// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-line-1 {
    background-color: var(--link-primary-text);
    height: 2px;
}

.menu-line-2 {
    background-color: var(--link-primary-text);
    height: 2px;

}

.menu-line-3 {
    background-color: var(--link-primary-text);
    height: 2px;
}

.menu {
    width: 24px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}


.close-line-1 {
    background-color: var(--link-primary-text);
    height: 2px;
    transform: rotate(-45deg) translateY(1px);
}

.close-line-2 {
    display: none;
}

.close-line-3 {
    background-color: var(--link-primary-text);
    height: 2px;
    transform: rotate(45deg) translateY(-1px);
}

.close {
    width: 32px;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/styles/menu.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,WAAW;AACf;;AAEA;IACI,0CAA0C;IAC1C,WAAW;;AAEf;;AAEA;IACI,0CAA0C;IAC1C,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;;AAGA;IACI,0CAA0C;IAC1C,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0CAA0C;IAC1C,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".menu-line-1 {\n    background-color: var(--link-primary-text);\n    height: 2px;\n}\n\n.menu-line-2 {\n    background-color: var(--link-primary-text);\n    height: 2px;\n\n}\n\n.menu-line-3 {\n    background-color: var(--link-primary-text);\n    height: 2px;\n}\n\n.menu {\n    width: 24px;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n\n\n.close-line-1 {\n    background-color: var(--link-primary-text);\n    height: 2px;\n    transform: rotate(-45deg) translateY(1px);\n}\n\n.close-line-2 {\n    display: none;\n}\n\n.close-line-3 {\n    background-color: var(--link-primary-text);\n    height: 2px;\n    transform: rotate(45deg) translateY(-1px);\n}\n\n.close {\n    width: 32px;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
