// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
    display: flex;
    gap: var(--spacing-20);
}

.gallery-container-mobile {
    display: none;
}

.img-gallery-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-20);
}

.img-gallery-img {
    scale: 1;
    opacity: .8;
    border-radius: var(--card-border-radius);
    -webkit-transition: all .1s ease-out ;
    -moz-transition: all .1s ease-out;
    -o-transition: all .1s ease-out;
    transition: all .1s ease-out;
}

.img-gallery-img:hover {
    scale: 1.2;
    opacity: 1;
    z-index: 1000;
    box-shadow: var(--card-shadow-hover);
    -webkit-transition: all .1s ease-in ;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

@media screen and (max-width: 1200px) {

    .gallery-container {
        display: none;
    }

    .gallery-container-mobile {
        display: flex;
        gap: var(--spacing-10);
    }

    .img-gallery-col {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--spacing-10);
    }

    .img-gallery-img {
        opacity: 1;
    }

    .img-gallery-img:hover {
        scale: 1;
        opacity: 1;
        box-shadow: none;
    }

}`, "",{"version":3,"sources":["webpack://./src/styles/about.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,QAAQ;IACR,WAAW;IACX,wCAAwC;IACxC,qCAAqC;IACrC,iCAAiC;IACjC,+BAA+B;IAC/B,4BAA4B;AAChC;;AAEA;IACI,UAAU;IACV,UAAU;IACV,aAAa;IACb,oCAAoC;IACpC,oCAAoC;IACpC,gCAAgC;IAChC,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,sBAAsB;IAC1B;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,WAAW;QACX,sBAAsB;IAC1B;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,QAAQ;QACR,UAAU;QACV,gBAAgB;IACpB;;AAEJ","sourcesContent":[".gallery-container {\n    display: flex;\n    gap: var(--spacing-20);\n}\n\n.gallery-container-mobile {\n    display: none;\n}\n\n.img-gallery-col {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: var(--spacing-20);\n}\n\n.img-gallery-img {\n    scale: 1;\n    opacity: .8;\n    border-radius: var(--card-border-radius);\n    -webkit-transition: all .1s ease-out ;\n    -moz-transition: all .1s ease-out;\n    -o-transition: all .1s ease-out;\n    transition: all .1s ease-out;\n}\n\n.img-gallery-img:hover {\n    scale: 1.2;\n    opacity: 1;\n    z-index: 1000;\n    box-shadow: var(--card-shadow-hover);\n    -webkit-transition: all .1s ease-in ;\n    -moz-transition: all .1s ease-in;\n    -o-transition: all .1s ease-in;\n    transition: all .1s ease-in;\n}\n\n@media screen and (max-width: 1200px) {\n\n    .gallery-container {\n        display: none;\n    }\n\n    .gallery-container-mobile {\n        display: flex;\n        gap: var(--spacing-10);\n    }\n\n    .img-gallery-col {\n        display: flex;\n        flex-direction: column;\n        width: 100%;\n        gap: var(--spacing-10);\n    }\n\n    .img-gallery-img {\n        opacity: 1;\n    }\n\n    .img-gallery-img:hover {\n        scale: 1;\n        opacity: 1;\n        box-shadow: none;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
