// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    display: flex;
    align-items: center;
    color: var(--motif-primary);
    font-family: var(--headline-font-Family), sans-serif;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
}

.logo > .logo-divider {
    margin: var(--spacing-10) var(--spacing-20);
    width: 1px;
    height: 30px;
    background-color: var(--border-divider);
}

/* Mobile, Tablet, Small Laptop*/
@media screen and (max-width: 1200px) {
    .logo {
        padding: var(--spacing-20);
        font-size: 2rem;
        line-height: 2.5rem;
    }
    
    .logo > .logo-divider {
        margin: var(--spacing-10) var(--spacing-10);
        width: 1px;
        height: 20px;
        background-color: var(--border-divider);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/logo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,oDAAoD;IACpD,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,2CAA2C;IAC3C,UAAU;IACV,YAAY;IACZ,uCAAuC;AAC3C;;AAEA,gCAAgC;AAChC;IACI;QACI,0BAA0B;QAC1B,eAAe;QACf,mBAAmB;IACvB;;IAEA;QACI,2CAA2C;QAC3C,UAAU;QACV,YAAY;QACZ,uCAAuC;IAC3C;AACJ","sourcesContent":[".logo {\n    display: flex;\n    align-items: center;\n    color: var(--motif-primary);\n    font-family: var(--headline-font-Family), sans-serif;\n    font-size: 2.5rem;\n    line-height: 3.5rem;\n    text-transform: uppercase;\n}\n\n.logo > .logo-divider {\n    margin: var(--spacing-10) var(--spacing-20);\n    width: 1px;\n    height: 30px;\n    background-color: var(--border-divider);\n}\n\n/* Mobile, Tablet, Small Laptop*/\n@media screen and (max-width: 1200px) {\n    .logo {\n        padding: var(--spacing-20);\n        font-size: 2rem;\n        line-height: 2.5rem;\n    }\n    \n    .logo > .logo-divider {\n        margin: var(--spacing-10) var(--spacing-10);\n        width: 1px;\n        height: 20px;\n        background-color: var(--border-divider);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
